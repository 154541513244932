body {
  margin: 0;
  padding: 0;
  font-family: source-han-sans-simplified-c, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.paginationList{
    margin: 0 5px;
}
.activeLink{
    color: black;
}

.blur {
    -webkit-filter: blur(5px);
            filter: blur(5px);
}
